import { container } from 'tsyringe';
import { logger } from '~/logging';
import type { ConsentsResponseV3 } from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export const apiGetConsents = async (correlationId: string): Promise<ConsentsResponseV3> => {
  const client = container.resolve(CustomerClient);
  const response = await client.getConsentsV3(correlationId, 'aphTinman', undefined, undefined);
  logger.info(`Correlation ID: ${correlationId} getConsents() was called`);
  return response;
};
