import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { logger } from '~/logging';
import type { IMemberConsentV3 } from '~/services/generated/CustomerClient';
import { apiGetMemberConsents, apiSaveMemberConsents } from './member-consent.api';
import { type MemberConsentsState, initialState } from './use-member-consent-state';

interface MemberConsentsStore {
  memberConsents: MemberConsentsState;
  actions: {
    getMemberConsents: () => void;
    saveMemberConsents: (body: IMemberConsentV3[]) => void;
    resetSubmittEventConsent: () => void;
  };
}

export const useMemberConsentStore = create<MemberConsentsStore>()(
  devtools<MemberConsentsStore>(
    (set, get) => ({
      memberConsents: initialState,
      actions: {
        getMemberConsents: async () => {
          const correlationId = uuidv4();
          set(
            {
              memberConsents: {
                ...initialState,
                loading: true,
                error: false,
                updated: Date.now(),
                fetchFailed: null,
              },
            },
            false,
            'Fetching memberConsents',
          );
          try {
            const response = await apiGetMemberConsents(correlationId);
            logger.info(
              `getMemberConsentSaga, Correlation ID: ${correlationId} was fecthed successfully`,
            );
            set(
              {
                memberConsents: {
                  ...initialState,
                  data: response || initialState.data,
                  loading: false,
                  error: false,
                  updated: Date.now(),
                  fetchFailed: false,
                },
              },
              false,
              'Fetching memberConsents success',
            );
          } catch (event) {
            const { userMessage, status, httpStatusCode } = event as {
              userMessage: string;
              status: string;
              httpStatusCode: number;
            };
            const errorMessage = `${userMessage || 'Något gick fel!'}`;
            logger.error(
              ` getMemberConsentSaga, Correlation ID: ${correlationId} failed to update | errorMessage: ${errorMessage} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );

            set(
              {
                memberConsents: {
                  ...initialState,
                  loading: false,
                  error: true,
                  errorMessage,
                  updated: Date.now(),
                  fetchFailed: true,
                },
              },
              false,
              'Fetching memberConsents failed',
            );
          }
        },
        saveMemberConsents: async (body) => {
          const correlationId = uuidv4();
          set(
            {
              memberConsents: {
                ...initialState,
                loading: false, // false so the page is not loading again
                error: false,
                updated: Date.now(),
                submitting: true,
              },
            },
            false,
            'Saving memberConsents',
          );
          try {
            const response = await apiSaveMemberConsents(body, correlationId);
            logger.info(
              `updateMemberConsentSaga, Correlation ID: ${correlationId} was successfully`,
            );

            set(
              {
                memberConsents: {
                  ...initialState,
                  data: response || initialState.data,
                  loading: false,
                  error: false,
                  updated: Date.now(),
                  submitting: false,
                  success: true,
                },
              },
              false,
              'Saving memberConsents success',
            );
          } catch (event) {
            const { userMessage, status, httpStatusCode } = event as {
              userMessage: string;
              status: string;
              httpStatusCode: number;
            };
            const errorMessage = `${
              userMessage || 'Oj, något gick fel när dina samtyckena skulle sparas'
            }`;
            logger.error(
              ` updateMemberConsentSaga, Correlation ID: ${correlationId} failed to update | errorMessage: ${errorMessage} | status: ${status} | httpStatusCode: ${httpStatusCode}`,
            );

            set(
              {
                memberConsents: {
                  ...initialState,
                  loading: false,
                  error: true,
                  errorMessage,
                  updated: Date.now(),
                  submitting: false,
                  success: false,
                },
              },
              false,
              'SAving memberConsents failed',
            );
          }
        },
        resetSubmittEventConsent: async () => {
          const state = get().memberConsents;
          set(
            {
              memberConsents: {
                ...state,
                submitting: null,
                success: null,
              },
            },
            false,
            'Reset',
          );
        },
      },
    }),
    {
      name: 'UseMemberContents',
      anonymousActionType: 'UseMemberContents',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useMemberConsentActions = () => useMemberConsentStore((state) => state.actions);
