import { useEffect, useState } from 'react';
import type { PanelType } from './panels.panel-type';
import { usePanelActions, usePanelStore } from './use-panel-store';

type UsePanelReturn = {
  isPanelActive: boolean;
  setPanelActive: () => void;
  setPanelInactive: () => void;
  togglePanelActive: () => void;
};

export const usePanel = (panelType: PanelType): UsePanelReturn => {
  const {
    panel: { activePanels },
  } = usePanelStore();
  const { panelsSetPanelActive, panelsSetPanelInactive } = usePanelActions();
  const isPanelActiveInAppState = activePanels[panelType];
  const [localIsPanelActive, setLocalIsPanelActive] = useState(isPanelActiveInAppState);

  useEffect(() => {
    if (isPanelActiveInAppState === false && localIsPanelActive === true) {
      setLocalIsPanelActive(false);
    }
  }, [localIsPanelActive, isPanelActiveInAppState, setLocalIsPanelActive]);

  return {
    isPanelActive: localIsPanelActive,
    setPanelActive: () => {
      setLocalIsPanelActive(true);
      panelsSetPanelActive({ panelType });
    },
    setPanelInactive: () => {
      panelsSetPanelInactive({ panelType });
    },
    togglePanelActive: () => {
      if (localIsPanelActive === true) {
        panelsSetPanelInactive({ panelType });
      }
      if (localIsPanelActive === false) {
        setLocalIsPanelActive(true);
        panelsSetPanelActive({ panelType });
      }
    },
  };
};
