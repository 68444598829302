enum PanelType {
  MORE_INFO_GDPR_CONSENTS = 'openMoreInfoGdprConsents',
  MORE_INFO_GDPR_CONSENTS_BONUS = 'openMoreInfoGdprConsentsBonus',
  MORE_INFO_GDPR_CONSENTS_RETAIL_MEDIA = 'openMoreInfoGdprConsentsRetailMedia',
  MORE_INFO_GDPR_CONSENTS_PERSONAL_OFFERS = 'openMoreInfoGdprConsentsPersonalOffers',
  ADD_ANIMAL_CONSENT = 'addAnimalConsent',
  ADD_POA_CONSENT = 'addPoaConsent',
  PRODUCT_VARIANT_DETAIL_PAGE_COMPLIANCE_INFORMATION_ICON_TEXT = 'productVariantDetailPageComplianceInformationIconText',
  LOGOUT = 'logout',
  MENU = 'menu',
}

export { PanelType };
