import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';

export const ConsentInfoDetailsPersonalOffer = () => {
  const intl = useIntl();

  return (
    <section className="flex flex-col gap-1">
      <Typography typography="headingExtraLarge" color="text-brand" asChild>
        <h1>
          <FormattedMessage id="CONSENT_INFO.HEADER.PERSONAL_OFFERS" />
        </h1>
      </Typography>
      <Typography>
        <FormattedMessage id="CONSENT_INFO.PARAGRAF1" />
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{
          __html: intl.messages['CONSENT_INFO.PARAGRAF2'].toString(),
        }}
      />
      <Typography typography="headingMedium" asChild>
        <h2>
          <FormattedMessage id="CONSENT_INFO.HEADER_PERSONAL_OFFERS" />
        </h2>
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{
          __html: intl.messages['CONSENT_INFO.PARAGRAF4'].toString(),
        }}
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: intl.messages['CONSENT_INFO.PARAGRAF5'].toString(),
        }}
      />
      <Typography>
        <FormattedMessage id="CONSENT_INFO.PARAGRAF6" />
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{
          __html: intl.messages['CONSENT_INFO.PARAGRAF7'].toString(),
        }}
      />
      <Typography>
        <FormattedMessage id="CONSENT_INFO.PARAGRAF8" />
      </Typography>
    </section>
  );
};
