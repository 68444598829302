import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { PanelType } from './panels.panel-type';

type PanelsSetPanelActive = { panelType: PanelType };
type PanelsSetPanelInactive = { panelType: PanelType };

type PanelState = {
  activePanels: {
    [PanelType.MORE_INFO_GDPR_CONSENTS]: boolean;
    [PanelType.MORE_INFO_GDPR_CONSENTS_BONUS]: boolean;
    [PanelType.MORE_INFO_GDPR_CONSENTS_PERSONAL_OFFERS]: boolean;
    [PanelType.MORE_INFO_GDPR_CONSENTS_RETAIL_MEDIA]: boolean;
    [PanelType.ADD_ANIMAL_CONSENT]: boolean;
    [PanelType.ADD_POA_CONSENT]: boolean;
    [PanelType.LOGOUT]: boolean;
    [PanelType.MENU]: boolean;
    [PanelType.PRODUCT_VARIANT_DETAIL_PAGE_COMPLIANCE_INFORMATION_ICON_TEXT]: boolean;
    updated: number;
  };
};

interface PanelStore {
  panel: PanelState;
  actions: {
    panelsSetPanelActive: (data: PanelsSetPanelActive) => void;
    panelsSetPanelInactive: (data: PanelsSetPanelInactive) => void;
  };
}

const initalActivePanelsState = {
  [PanelType.MORE_INFO_GDPR_CONSENTS]: false,
  [PanelType.MORE_INFO_GDPR_CONSENTS_BONUS]: false,
  [PanelType.MORE_INFO_GDPR_CONSENTS_PERSONAL_OFFERS]: false,
  [PanelType.MORE_INFO_GDPR_CONSENTS_RETAIL_MEDIA]: false,
  [PanelType.ADD_ANIMAL_CONSENT]: false,
  [PanelType.ADD_POA_CONSENT]: false,
  [PanelType.LOGOUT]: false,
  [PanelType.MENU]: false,
  [PanelType.PRODUCT_VARIANT_DETAIL_PAGE_COMPLIANCE_INFORMATION_ICON_TEXT]: false,
};

const initialState = {
  activePanels: {
    ...initalActivePanelsState,
    updated: 0,
  },
};

export const usePanelStore = create<PanelStore>()(
  devtools<PanelStore>(
    (set, get) => ({
      panel: initialState,
      actions: {
        panelsSetPanelActive: (data) => {
          const state = get().panel;
          set(
            {
              panel: {
                ...state,
                activePanels: {
                  ...state.activePanels,
                  [data.panelType]: true,
                  updated: Date.now(),
                },
              },
            },
            false,
            'Set panel active',
          );
        },
        panelsSetPanelInactive: (data) => {
          const state = get().panel;
          set(
            {
              panel: {
                ...state,
                activePanels: {
                  ...state.activePanels,
                  [data.panelType]: false,
                  updated: Date.now(),
                },
              },
            },
            false,
            'Set panel inactive',
          );
        },
      },
    }),
    {
      name: 'UsePanel',
      anonymousActionType: 'UsePanel',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const usePanelActions = () => usePanelStore((state) => state.actions);
