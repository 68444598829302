import { container } from 'tsyringe';
import { logger } from '~/logging';
import type {
  IMemberConsentV3,
  MemberConsentV3,
  MemberConsentsResponseV3,
} from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export const apiGetMemberConsents = async (
  correlationId: string,
): Promise<MemberConsentsResponseV3> => {
  const client = container.resolve(CustomerClient);
  const response = await client.getMemberConsentsForMeV3(correlationId, 'aphTinman', undefined);
  logger.info(`Correlation ID: ${correlationId} getMemberConsentsForMe() was called`);
  return response;
};

export const apiSaveMemberConsents = async (
  body: IMemberConsentV3[],
  correlationId: string,
): Promise<MemberConsentsResponseV3> => {
  const client = container.resolve(CustomerClient);
  const response = await client.saveMemberConsentsForMeV3(
    undefined,
    correlationId,
    'aphTinman',
    body as MemberConsentV3[],
  );
  logger.info(`Correlation ID: ${correlationId} saveMemberConsentsForMe() was called`);
  return response;
};
