import { ConsentsResponseV3, type IConsentsResponseV3 } from '~/services/generated/CustomerClient';

export type ConsentsState = {
  data: IConsentsResponseV3;
  error: boolean;
  errorMessage: string | null;
  loading: boolean;
  updated: number;
};

export const initialState = {
  data: new ConsentsResponseV3(),
  error: false,
  errorMessage: null,
  loading: false,
  updated: 0,
};
