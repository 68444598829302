import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { FeatureToggleComponent, On } from '../../feature-toggle/feature-toggle.component';
import { ConsentInfoDetailsBonus } from './consent-info-details/consent-info-details-bonus.component';
import { ConsentInfoDetailsPersonalOffer } from './consent-info-details/consent-info-details-personal-offer.component';
import { ConsentInfoDetailsRetailMedia } from './consent-info-details/consent-info-details-retail-media.component';

type ConsentInfoPageComponentProps = {
  consentType?: string;
};

export const ConsentInfoPageComponent: React.FC<ConsentInfoPageComponentProps> = ({
  consentType,
}) => (
  <div className="max-w-screen-md">
    <div className="flex flex-col gap-2">
      {(!consentType || consentType === 'Bonus') && ConsentInfoDetailsBonus()}

      {(!consentType || consentType === 'PersonalOffers') && ConsentInfoDetailsPersonalOffer()}

      <FeatureToggleComponent featureName="aph_features_retail_media_consent">
        <On>
          {(!consentType || consentType === 'RetailMedia') && ConsentInfoDetailsRetailMedia()}
        </On>
      </FeatureToggleComponent>

      <div className="flex flex-col gap-1">
        <Typography typography="headingSmall" asChild>
          <h3>
            <FormattedMessage id="CONSENT_INFO.CONTACT.US.EMAIL" />
          </h3>
        </Typography>
        <Typography>
          <FormattedMessage id="CONSENT_INFO.CONTACT.EMAIL.ADDRESS" />
        </Typography>
      </div>
    </div>
  </div>
);
