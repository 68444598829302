import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';

export const ConsentInfoDetailsBonus = () => {
  const intl = useIntl();

  return (
    <section className="flex flex-col gap-1">
      <Typography typography="headingExtraLarge" color="text-brand" asChild>
        <h1>
          <FormattedMessage id="CONSENT_INFO.HEADER.BONUS" />
        </h1>
      </Typography>
      <Typography>
        <FormattedMessage id="CONSENT_INFO.PARAGRAF1" />
      </Typography>
      <Typography
        dangerouslySetInnerHTML={{
          __html: intl.messages['CONSENT_INFO.PARAGRAF2'].toString(),
        }}
      />
      <Typography typography="headingMedium" asChild>
        <h2>
          <FormattedMessage id="CONSENT_INFO.HEADER_BONUS" />
        </h2>
      </Typography>
      <Typography>
        <FormattedMessage id="CONSENT_INFO.PARAGRAF3" />
      </Typography>
    </section>
  );
};
