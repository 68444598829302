import { FormattedMessage } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';

export const ConsentInfoDetailsRetailMedia = () => {
  return (
    <section className="flex flex-col gap-1">
      <div className="flex flex-col gap-1">
        <Typography typography="headingExtraLarge" color="text-brand" asChild>
          <h1>
            <FormattedMessage id="CONSENT_INFO.HEADER.RETAIL_MEDIA" />
          </h1>
        </Typography>
      </div>

      <div className="flex flex-col gap-1">
        <Typography typography="headingMedium" asChild>
          <h2>
            <FormattedMessage id="CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF1" />
          </h2>
        </Typography>
        <Typography>
          Apotek Hjärtat kommer att skapa en kundprofil om dig för att ge dig relevanta och
          personligt anpassade annonser. Vi kommer också att använda dina aggregerade uppgifter för
          att mäta och utvärdera effekten av dessa annonser, för att anpassa framtida annonser till
          dig och för att förbättra tjänsten till dig. Du kan läsa mer om hur vi använder dina
          personuppgifter för annonsering på apotekhjartat.se/dataskydd/annonsering.
        </Typography>
      </div>

      <div className="flex flex-col gap-1">
        <Typography typography="headingMedium" asChild>
          <h2>
            <FormattedMessage id="CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF2" />
          </h2>
        </Typography>
        <div className="flex flex-col gap-2">
          <ul
            className="list-disc"
            style={{ padding: '0 0 0 25px', marginTop: 0, marginBottom: 0 }}
          >
            <li>Identitetsuppgifter (kundID)</li>
            <li>Användargenererad information (t ex klick, sidvisningar) *</li>
            <li>Kontaktuppgifter (postkod, ort)</li>
            <li>Demografiska uppgifter (t ex kön och ålder)</li>
            <li>
              Kundsegment (t.ex. om du köper mycket solskyddsprodukter eller hur mycket du handlar
              på Apotek Hjärtat)
            </li>
            <li>Köpinformation (t ex köpta produkter, belopp på köp)</li>
          </ul>
          <Typography>
            *Användargenererad information för detta ändamål samlas endast in om du samtycker till
            marknadsföringskakor.
          </Typography>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Typography typography="headingMedium" asChild>
          <h2>
            <FormattedMessage id="CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF3" />
          </h2>
        </Typography>
        <Typography className="font-bold">Ansvariga parter är:</Typography>
        <ul className="list-disc" style={{ padding: '0 0 0 25px', marginTop: 0, marginBottom: 0 }}>
          <li>Apotek Hjärtat</li>
          <li>Annonsörer utvalda av Apotek Hjärtat</li>
        </ul>
        <Typography>
          Hjärtat är tillsammans med utvalda annonsörer övergripande gemensamt ansvariga för att
          tillhandahålla relevanta och personligt anpassade annonser i Apotek Hjärtats digitala
          kanaler.
        </Typography>
        <Typography>
          För vissa aktiviteter, så som bearbetning av data för att skapa en kundprofil åt dig som
          kund, är enbart Apotek Hjärtat personuppgiftsansvarig.
        </Typography>
        <Typography>
          Dina personuppgifter kommer aldrig att vara tillgängliga för annonsörer i ett format där
          du som kund kan identifieras, utan kommer enbart att vara synliga på en övergripande,
          sammanfattad nivå. Du kan läsa mer om vilka annonsörer Apotek Hjärtat har ett gemensamt
          personuppgiftsansvar med, samt hur det gemensamma ansvaret fungerar här:
          [apotekhjartat.se/dataskydd/annonsering]
        </Typography>
      </div>

      <div className="flex flex-col gap-1">
        <Typography typography="headingMedium" asChild>
          <h2>
            <FormattedMessage id="CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF4" />
          </h2>
        </Typography>
        <div className="flex flex-col gap-2">
          <ul
            className="list-disc"
            style={{ padding: '0 0 0 25px', marginTop: 0, marginBottom: 0 }}
          >
            <li>Apotekhjartat.se</li>
            <li>Apotek Hjärtats app</li>
          </ul>
          <Typography>
            För att vi ska kunna visa dig personligt anpassade annonser behöver du även ha samtyckt
            till placeringen av marknadsföringskakor. Ändra inställningar för kakor
            [apotekhjartat.se/cookieinstallningar/]
          </Typography>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Typography typography="headingSmall" asChild>
          <h3>
            <FormattedMessage id="CONSENT_INFO.HEADER_RETAIL_MEDIA.PARAGRAF5" />
          </h3>
        </Typography>
        <div className="flex flex-col">
          <Typography>Så används dina personuppgifter vid annonsering [länk]</Typography>
          <Typography>
            Så hanterar Apotek Hjärtat dina personuppgifter och dina rättigheter [länk]
          </Typography>
          <Typography className="mt-1">
            Du kan ändra ditt samtyckesval när du vill under din profil
            [https://www.apotekhjartat.se/minasidor/minauppgifter]
          </Typography>
        </div>
      </div>
    </section>
  );
};
