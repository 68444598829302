import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { Button } from '@aph/ui/components/button/button';
import { ConsentInfoPageComponent } from './consent-info-page.component';

export type ConsentInfoProps = {
  onClose?: () => void;
  consentType?: string;
};

export const ConsentInfo: React.FC<ConsentInfoProps> = ({ onClose, consentType }) => (
  <div className="p-2 md:p-4">
    <div className="flex flex-col items-end">
      <ButtonCircle icon="Close" variant="white" onClick={onClose} aria-label="Stäng" />
    </div>
    <ConsentInfoPageComponent consentType={consentType} />
    <div className="flex justify-center px-10 pt-2">
      <Button
        className="min-w-[270px]"
        variant="secondary"
        color="primary"
        onClick={onClose}
        data-testid="BACK.BUTTON"
      >
        <FormattedMessage id="CONSENT_INFO.BUTTON.BACK" />
      </Button>
    </div>
  </div>
);
