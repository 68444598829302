import {
  type IMemberConsentsResponseV3,
  MemberConsentsResponseV3,
} from '~/services/generated/CustomerClient';

export type MemberConsentsState = {
  data: IMemberConsentsResponseV3;
  error: boolean;
  errorMessage: string | null;
  loading: boolean;
  updated: number;
  submitting: boolean | null;
  success: boolean | null;
  fetchFailed: boolean | null;
};

export const initialState = {
  data: new MemberConsentsResponseV3(),
  error: false,
  errorMessage: null,
  loading: false,
  updated: 0,
  submitting: null,
  success: null,
  fetchFailed: null,
};
